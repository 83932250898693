<main class="main" *ngIf="showInfo">
  <div class="nav-wrap">
    <ul class="nav">
      <li class="nav__item">
        <p-splitButton
          label="Save"
          severity="contrast"
          [model]="langItems"
          size="small"
        >
          <ng-template pTemplate="content">
            <span class="flex align-items-center font-bold ">
              <img
                alt="logo"
                [src]="'assets/icons/flags/' + selectedLangItem.label + '.svg'"
                style="height: 1rem; margin-right: 0.5rem"
              />

              <span>{{ selectedLangItem.label }}</span>
            </span>
          </ng-template>
        </p-splitButton>
      </li>
      <li class="nav__item">
        <button class="btn btn-primary rounded  p-2 " (click)="downloadVcf(id,HashKey)">{{"buttons.telecharger" |translate}}</button>
      </li>
    </ul>
  </div>

  <div class="wrapper sticky-parent">
    <!-- Sidebar -->
    <aside class="sidebar">
      <div class="sticky-column">
        <div *ngIf="vCard.image">
          <svg class="img-thumbnail circle"  viewBox="0 0 188 188">
            <g class="avatar__box">
              <image
              
                [attr.xlink:href]="
                            mediaUrl + '/' + HashKey + '/' + vCard.image
                          "
                height="100%"
                width="100%"
              />
            </g>
          </svg>
        </div>
        <div class="text-center">
          <h3 class="title sidebar__user-name">
            {{ vCard.civility }} {{ vCard.firstName }}
            {{ vCard.lastName }}
          </h3>
          <div class="badge badge--gray">{{ vCard.function }}</div>

          <!-- Social -->
          <div *ngIf="vCard.socialNetworks.length > 0" class="social">
            <a
              class="social__link"
              target="_blank"
              [href]="social.link"
              *ngFor="let social of vCard.socialNetworks"
              ><i [ngClass]="'font-icon icon-' + social.name"></i
            ></a>
          </div>
        </div>

        <ul class="contact-block">
          <li
            class="contact-block__item"
            data-toggle="tooltip"
            data-placement="top"
            title="Address"
          >
            <i class="font-icon icon-map-pin mt-1"></i>
            <a href="#">
              <span>
                {{ vCard.address.street }} {{ vCard.address.city }}
                {{ vCard.address.country }}</span
              >
            </a>
          </li>
          <li
            *ngIf="vCard.emails | findByProperty : 'isPrincipal' : 'True'"
              class="contact-block__item"
              data-toggle="tooltip"
              data-placement="top"
              title="E-mail"
            >
              <i class="font-icon icon-mail mt-1"></i>
              <a

                [href]="
                  'mailto:' +
                  (vCard.emails | findByProperty : 'isPrincipal' : 'True')?.address
                  
                "
                >{{
                  (vCard.emails | findByProperty : "isPrincipal" : 'True')?.address
                }}</a
              >
            </li>
            <li
            *ngIf="vCard.phoneNumbers | findByProperty : 'isPrincipal' :'True'"
            class="contact-block__item"
            data-toggle="tooltip"
            data-placement="top"
            title="Phone"
          >
            <i class="font-icon icon-smartphone mt-1"></i>
            <a
              [href]="
                'phone:' +
                (vCard.phoneNumbers | findByProperty : 'isPrincipal' : 'True')
                  ?.prefix +
                (vCard.phoneNumbers | findByProperty : 'isPrincipal' : 'True')
                  ?.phone
              "
            >
              {{
                (vCard.phoneNumbers | findByProperty : "isPrincipal" : "True")
                  ?.prefix
              }}
              {{
                (vCard.phoneNumbers | findByProperty : "isPrincipal" : "True")
                  ?.phone
              }}</a
            >
          </li>
        </ul>
        
          <a class="btn text-left p-3" routerLink="[]" (click)="scrollToFragment()"   fragment="contact" 
            > 
              {{ "buttons.contacts" | translate }} 
            </a
          >
          <a class="btn text-left mt-2 w-100 p-3" *ngIf="vCard.appointment && vCard.appointment != ''" [href]='vCard.appointment' target="_blank"  
          >
          {{ "buttons.appointment" | translate }}</a>
        </div>
      </aside>

    <!-- Content -->
    <div class="content">
      <!-- About -->
      <div *ngIf="vCard.about" class="section mt-0">
        <h1 class="title title--h1 title__separate">
          {{ "sections.about.title" | translate }}
        </h1>
        <div class="pt-2 pt-sm-3" [innerHTML]="vCard.about | safeHtml"></div>
      </div>

      <!-- Services -->
      <div *ngIf="vCard.services.length > 0" class="section">
        <h2 class="title title--h2 title__separate">
          {{ "sections.services.title" | translate }}
        </h2>
        <div class="pt-2 pt-sm-3 timeline">
          <!-- Item -->
          <article
            class="timeline__item"
            *ngFor="let service of vCard.services"
          >
            <h5 class="title title--h3 timeline__title">{{ service.name }}</h5>

            <p
              class="timeline__description"
              [innerHTML]="service.description | safeHtml"
            ></p>
          </article>
        </div>
      </div>

      <!-- Products -->
      <div *ngIf="vCard.products.length > 0" class="section">
        <h2 class="title title--h2 title__separate">
          {{ "sections.products.title" | translate }}
        </h2>

        <!-- carosel -->

        <p-carousel
          [value]="vCard.products"
          [numVisible]="2"
          [numScroll]="1"
          [circular]="true"
          [autoplayInterval]="0"
          [responsiveOptions]="responsiveOptions"
          [showNavigators]="true"
          [showIndicators]=""
          autoplayInterval="3000"
        >
          <ng-template let-product pTemplate="item">
            <div class="p-1">
              <div class="border rounded-[12px] p-2">
                <div class="mb-3 ">
                  <div class="relative mx-auto">
                    <svg >
                      <g class="avatar__hexagon">
                        <image
                         
                          [attr.xlink:href]="product.imageProduct"
                          [attr.xlink:href]="
                            mediaUrl + '/' + HashKey + '/' + product.imageProduct
                          "
                          height="100%"
                          width="100%"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              
                <div class="d-flex flex-column justify-content-between align-items-center">
                  <div class="mt-1 text-dark h4">
                    <div class="mb-1 ">
                      {{ product.name }}   
                    </div>
                    {{ "$" + product.price }}
                  </div>
                  <div>
                    
                    <a [href]="vCard.appointment" class="btn btn-primary rounded mt-2 p-2">{{"buttons.view-product" |translate}}</a>
                    
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
        <!-- carosel -->
      </div>

      <!-- Clients -->
      <div *ngIf="vCard.customers.length > 0" class="section">
        <h2 class="title title--h2 title__separate">
          {{ "sections.customers.title" | translate }}
        </h2>

        <p-carousel
            [value]="vCard.customers"
            [numVisible]="2"
            [numScroll]="1"
            [circular]="true"
            [autoplayInterval]="0"
            [responsiveOptions]="responsiveOptions"
            [showNavigators]="true"
            [showIndicators]=""
            autoplayInterval="3000"
          >
          >
            <ng-template let-customer pTemplate="item">
              <div class="p-1">
                <div class="border rounded-[12px] p-2">
                  <div class="mb-3 ">
                    <div class="relative mx-auto">
                      <svg >
                        <g class="avatar__hexagon">
                          <image
                            class="img-thumbnail"
                            [attr.xlink:href]="customer.logoSrc"
                            [attr.xlink:href]="
                              mediaUrl + '/' + HashKey + '/' + customer.logoSrc
                            "
                            alt = "customer.name"
                            height="100%"
                            width="100%"
                          />
                     
                        </g>
                      </svg>
                    </div>
                  </div>
                
                </div>
              </div>
            </ng-template>
          </p-carousel>
          <!-- carosel -->

      </div>

         <!-- Contact -->
      <div id="contact" class="section">
          <h2 class="title title--h2 title__separate">
            {{"sections.Contacts.title" | translate}}
          </h2>
          
          <form [formGroup]="ContactForm" action="">
            
              <div class="input-field  mt-3">
                <div class="row input-field  mt-3">
                  <!-- fullname -->
                  <div class="fullname col-xl-6 col-lg-6  col-sm-12">
                      <label for="">{{"form-label.FullName" | translate}}</label>
                      <input type="text"  pInputText formControlName="fullname"   class="form-control   " placeholder="Fullname" >
                   </div>
                    <div class="fullname col-xl-6 col-lg-6  col-sm-12">
                        <label for="" >{{"form-label.Phone" | translate}}</label>
                        <input type="text"   formControlName="phone"  class="form-control " placeholder="phone">
                    </div>  
                  <!-- phone -->
                </div>
              </div>
                
              <div class="email ">
                  <label for="" >{{"form-label.Email" | translate}}</label>
                  <input type="email"  pInputText formControlName="email"  class="form-control  " placeholder="Email">
              </div>
 
              <div class="input-field mt-3">
                <label for="">{{"form-label.Subject" | translate}}</label>
                <input type="text" pInputText formControlName="subject" class="form-control " placeholder="Suject">
              </div>
              <div class="input-field mt-3">
                <label for="">{{"form-label.Message" | translate}}</label>
                
                  <textarea 
                  formControlName="message"
                  rows="2"
                  cols="30" 
                  class="form-control "
                  pInputTextarea  
                  >
                  </textarea>

              </div>
              <button  (click)="createRV()"  type="button" class="btn btn-primary w-50 float-right p-2 m-2">{{ "buttons.valid" | translate }}</button>
           
          </form>
        </div>
      </div>
      <!-- Content End -->

      
       <!-- Contact -->

    </div>
    <!-- Wrapper End -->
  </main>
