import { Routes } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { ErrorComponent } from './error/error.component';

export const routes: Routes = [ 
    {
        path: ':HashKey', component: ProfileComponent,
        children:[{
            path:'[]',component:ProfileComponent
        }]
    },
    {
        path:'**',component:ErrorComponent
    },
    {
        path: 'error', redirectTo:ErrorComponent.toString(), pathMatch: 'full'
    },

];
