import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, Inject, inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Route, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '../shared.module';

import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuItem } from 'primeng/api';
import { Contact, VCard } from '../vcard.types';
import { vCard } from '../constants/vcard.constant';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';
import { StepperModule, StepperPanel } from 'primeng/stepper';

import { CarouselModule } from 'primeng/carousel';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [RouterOutlet, SharedModule, SplitButtonModule,StepperModule,InputTextModule,CarouselModule,InputTextareaModule ,ButtonModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers:[
    ApiService
  ]
})
export class ProfileComponent implements OnInit,AfterViewInit {
  mediaUrl:string = environment.mediaUrl;
  langItems: MenuItem[] = [
    {
      label: 'fr',
      command: () => {
        this.updateLang(0);
      },
    },
    {
      label: 'en',
      command: () => {
        this.updateLang(1);
      },
    },
    {
      label: 'ar',
      command: () => {
        this.updateLang(2);
      },
    },
  ];
  selectedLangItem = this.langItems[0];

  vCard!: VCard;
  HashKey! :string | null;
  id!: number |null;
  dispaly:boolean = true
  showInfo:boolean = false;
  ContactForm!:FormGroup;
  contact!: Contact 
  responsiveOptions!: any[]
  constructor(private translate: TranslateService,
              private _apiService : ApiService,
              @Inject(Router) private router: Router,
              private _activatedRoute:ActivatedRoute,
              private titleService: Title,

            ) {
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');


      this._activatedRoute.paramMap.subscribe((params)=>{
        this.HashKey = params.get('HashKey')
        if (this.HashKey ) {
          this.getProfile(this.HashKey);
          
        } else {
            console.warn("HashKey not found in route parameters.");
        }
      });
  }

  getProfile(HashKey : string) {
    this._apiService.get("/BusinessCards/profile/"+HashKey).subscribe(
        {
          next:(vCard: VCard) => {
            this.vCard = vCard;
            this.id = vCard.id || null;
            this.titleService.setTitle("Business Card - " +this.vCard.firstName + " " + this.vCard.lastName);
            this.showInfo = true;
          },
          // 404
          error:(error:any)=>{
            this.router.navigate(['error'],{relativeTo:this._activatedRoute})
                      }
        }
      )
  }
   // profile
   downloadVcf(id:number|null ,HashKey:string|null){
    this._apiService.downloadVcf(`${id}/`+HashKey).subscribe(
      {
        next:(vCard:any)=>{
          console.log(vCard)
          this.showInfo = true;
          const url =window.URL.createObjectURL(vCard);
          const xy = document.createElement('a');
          xy.href=url;
          xy.download = `${"Business Card - " +this.vCard.firstName + " " + this.vCard.lastName}.vcf`;
          xy.click();
          window.URL.revokeObjectURL(url);

        },
        error:(err:any)=>{
          console.log(err)
        }
      }
    )
  }
  // contact 
  createRV(){
    if(this.ContactForm.valid){
      this.ContactForm.get('BusinessCardId')?.setValue(this.vCard.id);
      this._apiService.createContact(this.ContactForm.value).subscribe({
        next:(rest:any)=>{
          console.log(rest)
        },
        error:(err:any)=>{
          console.log('no valid data',err)
        }
      })
    }
  }
 

 
  updateLang(index: number) {
    this.selectedLangItem = this.langItems[index];
    this.translate.use(this.selectedLangItem.label as string);
  }


  ngAfterViewInit() {
    this.scrollToFragment();
  }

  scrollToFragment() {
    this._activatedRoute.fragment.subscribe(fragment => {
      if (fragment) {
        const element = document.getElementById(fragment);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }
  jumpto(section : any){
    document.getElementById(section)?.scrollIntoView({behavior:"smooth"})
    
  }
  ngOnInit(): void {

    this._activatedRoute.fragment.subscribe((value)=>{
      this.jumpto(value)
    })
   
    this.responsiveOptions = [
      {
          breakpoint: '1199px',
          numVisible: 3,
          numScroll: 1
      },
      {
          breakpoint: '991px',
          numVisible: 2,
          numScroll: 1
      },
      {
          breakpoint: '767px',
          numVisible: 3,
          numScroll: 1
      }
  ];
  this.ContactForm= new FormGroup({
    id : new FormControl(this.contact?.Id?? null),
    fullname  : new FormControl(this.contact?.FullName?? null,[Validators.required]) ,
    phone  : new FormControl(this.contact?.Phone?? null,[Validators.required]) ,
    email  : new FormControl(this.contact?.Email?? null,[Validators.required,Validators.email]) ,
    subject  : new FormControl(this.contact?.Subject?? null,[Validators.required]) ,
    message  : new FormControl(this.contact?.Message?? null,[Validators.required]) ,
    BusinessCardId : new FormControl(this.contact?.BusinessCardId?? null)

  })  
  }
}
