import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findByProperty'
})
export class FindByPropertyPipe implements PipeTransform {
  transform<T>(items: T[], propertyName: keyof T, value: any): T | undefined {
    if (!items || !propertyName) {
      return undefined;
    }
    return items.find(item => item[propertyName] === value);
  }
}
