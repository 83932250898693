import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Contact, VCard } from '../vcard.types';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    protected readonly apiUrl: string;

    constructor(
        protected httpClient: HttpClient
    ) {
        this.apiUrl = environment.apiUrl;
    }

    /**
     * Get detail by id
     * @param id 
     * @returns 
     */
    getById<TModel>(resourceEndpoint: string, id: number|string, includes?: Array<string>): Observable<TModel> {
        let httpParams = new HttpParams();

        if (includes) {
            httpParams = httpParams.set('with', includes.join(','))
        }

        const options = { params: httpParams };
        return this.httpClient.get<TModel>(
            `${this.apiUrl}${resourceEndpoint}/${id}`, options
        );
    }

    /**
     * 
     * @param dto 
     * @returns 
     */
    create<TModel, TDto>(resourceEndpoint: string, dto: TDto): Observable<TModel> {
        return this.httpClient.post<TModel>(
            `${this.apiUrl}${resourceEndpoint}`,
            dto
        );
    }

    /**
     * 
     * @param id 
     * @param dto 
     * @returns 
     */
    update<TModel, TDto>(resourceEndpoint: string, id: string | number, dto: TDto): Observable<TModel> {
        return this.httpClient.put<TModel>(
            `${this.apiUrl}${resourceEndpoint}/${id}`,
            dto
        );
    }

    /**
     * 
     * @param id 
     * @param resourceEndpoint 
     * @returns 
     */
    deleteById(resourceEndpoint: string, id: number | string) {
        return this.httpClient.delete<number>(
            `${this.apiUrl}${resourceEndpoint}/${id}`
        );
    }

    /**
     * 
     * @param resourceEndpoint 
     * @param dto 
     * @param options 
     * @returns 
     */
    post(resourceEndpoint: string, dto: any, options: any = {}): any {
        return this.httpClient.post<any>(
            `${this.apiUrl}${resourceEndpoint}`,
            dto,
            options
        );
    }

    /**
     * 
     * @param resourceEndpoint 
     * @param params 
     * @returns 
     */
    get(resourceEndpoint: string, params?: any) {
        return this.httpClient.get<any>(`${this.apiUrl}${resourceEndpoint}`, {
            params: params,
        });
    }
    // contact 
    createContact(data : any){
        const baseUrl= "Contacts"
        return this.httpClient.post(`${this.apiUrl}/${baseUrl}`,data)
    }

    // Telcharger Profile 
    downloadVcf(id:number | string ,HashKey?:string):Observable<Blob>{
        const base= `/businessCards/vcf`
        return this.httpClient.get(`${this.apiUrl}${base}/${id}`,{responseType:'blob'})
    }

    /**
     * 
     * @param resourceEndpoint 
     * @param params 
     * @returns 
     */
    delete(resourceEndpoint: string, params?: any) {
        return this.httpClient.delete<any>(
            `${this.apiUrl}${resourceEndpoint}`,
            { params: params }
        );
    }

    /**
     * 
     * @param resourceEndpoint 
     * @param dto 
     * @returns 
     */
    put<TModel, TDto>(resourceEndpoint: string, dto: TDto) {
        return this.httpClient.put<TModel>(
            `${this.apiUrl}${resourceEndpoint}`,
            dto
        );
    }
}
